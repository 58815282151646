.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_row {
    margin: 20px;
  }

  img {
    max-height: 200px;
    max-width: 200px;
  }

  button {
    margin: 10px 0px;
    padding: 10px;
    border-radius: 20px;
  }
}
