.main_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  :hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
  }
}

.avatar_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 5px;

  img {
    max-width: 32px;
    max-height: 32px;
    margin-right: 5px;
  }
}

.current_organization_title {
  text-decoration: underline;
  border: solid 3px;
}
