.main_container {
  max-height: 100vh;
  .cropper_container {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    img: {
      max-width: 100%;
    }
  }
}

.buttons_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .buttons {
    margin: 10px;
    max-width: 30%;
  }
}
