.container {
  padding: 20px 5%;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  width: 100%;
}

.image_gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  gap: 10px;
  padding: 0px;
}

.list_view {
  padding: 0px;
}

.action_bar {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.action_button {
  margin: 2px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.tool_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  .search_input {
    width: 50%;
  }
  .parent_button {
    margin-right: auto;
  }
}
