li {
  flex-basis: 150px; /*width: 350px;*/
  position: relative;
  cursor: pointer;
  // &:hover .overlay {
  //   transform: scale(0);
  // }

  .image_item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
      width: auto;
      vertical-align: middle;
      border-radius: 5px;
      height: 128px;
      width: 128px;
      // max-height: 128px;
      // border: 1px solid black;
    }
  }

  .footer_container {
    width: 100%;
    padding: 0px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(1);
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  border-radius: 5px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0px;
  height: auto;
  padding: 0px;
  margin: 0px;
}
