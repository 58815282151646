.form_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 80%;
}
.form_section_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  // Media query for mobile phones
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.form_row {
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.input_field {
  flex-grow: 0;
  flex-basis: 240px;
}

.buttons_bar {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;

  button {
    margin: 10px 0px;
    padding: 10px;
    border-radius: 20px;
    width: 40%;
    max-width: 256px;
  }
}
