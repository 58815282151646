.img_presenter {
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  button {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: solid 5px;
    background-image: url("../../../assets/images/no_image.png");
    background-size: contain;
    cursor: pointer;
  }

  img {
    object-fit: cover;
  }
}
