.main_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   background-color: red;
  background: rgba(11, 11, 11, 0.2);
  z-index: 100000;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 30px;
  }
}
