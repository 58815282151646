.products_container {
  border: 1px solid #e0e0e0;
  width: 100%;
  max-height: 50%;
  overflow-y: auto;
}

.products_summary {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5px;
}
