.toolbar_content {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  .toolbar_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .user_name {
      margin: 1em;
    }

    .user_avatar {
      height: 40px;
      width: 40px;
    }
    .icons {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  .toolbar_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.logo {
  max-width: 160px;
  max-height: 160px;
}
