.backdrop_portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity and color as needed */
  z-index: 9999;
  backdrop-filter: blur(20px);
  overflow: hidden;
}
