.portal_content {
  min-width: 80%;
  max-width: 95%;
  height: 90%;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: white;
  z-index: 10000;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 30px;
  border: 5px solid black;
  box-shadow: 10px 5px 5px whitesmoke;
}
